.Header {
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 1;
}

.header-logo {
  margin-left: 3.445rem;
}

.navbar {
  @media (min-width: 800px) {
  margin: 0 auto;


ul {
  display: flex;

  li {
    list-style: none;
    margin: 1rem;

    a, div {
      text-decoration: none;
      text-transform: uppercase;
      color: black;
      cursor: pointer;

      &:hover {
        border-bottom: 3px solid red;
      }
    }
  }
}
}
}

.changer {
  display: flex;
  margin-right: 1.555rem;

  .menu {
    cursor: pointer;
    margin-right: 1rem;
    display: none;
  }
}

.closed {
  display: none;
}

@media (max-width: 800px) {
  .Header {
    justify-content: space-between;
    width: 100%;
  }

  .navbar {
    display: none;
    transition: 1s ease;
  }

  .navbar-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    inset: 0;
    background-color: white;
    height: 100%;

    ul {
      display: inherit;
      list-style-type: none;
      padding-inline-start: 0;

    li {
      margin: 2rem;

      @media (max-height: 600px) {
        margin-top: 24px !important;
        margin-bottom: 24px !important;
      }

      a, div {
        font-size: 20px;
        color: black;
        font-weight: bolder;
        cursor: pointer;

        @media (max-width: 400px) {
          font-size: 15px !important;
        }
      }
    }
  }
}
  .changer {
    .menu {
      display: block;
    }
    .menu-inactive {
      display: none;
    }
  }
  .closed {
      display: block;
      cursor: pointer;
  }
}
