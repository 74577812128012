.TarotProject {
  background: #636363;
  color: #fff;
  text-align: center;
  padding: 25px 20px;

  h1 {
    color: white;
    font-family: "Fanwood Text", serif;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 0.2rem;
    text-shadow: -1px 0, 0 1px, 1px 0, 0 -1px, -1px -1px, 1px 1px, -1px 1px,
      1px -1px;
  }

  .cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;

    .tarot-card {
      max-width: 150px;
      border: 2px solid #fff;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.7);
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 40px;

    .spooky-button {
      background-color: #333;
      color: #fff;
      border: 1px solid #555;
      padding: 0.5em 1em;
      font-size: 1em;
      font-family: "EB Garamond", serif;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background: #444;
      }
    }
  }
}
