.Footer {
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  padding-top: 15px;
  background: white;
}

.footer-logo {
  margin-left: 3.445rem;
}

.navbar {
  margin: 0 auto;
}

ul {
  display: flex;

  li {
    list-style: none;
    margin: 1rem;

    a, div {
      text-decoration: none;
      text-transform: uppercase;
      color: black;

      &:hover {
        border-bottom: 3px solid red;
      }
    }
  }
}

.changer {
  display: flex;
  margin-right: 1.555rem;

  .menu {
    cursor: pointer;
    margin-right: 1rem;
    display: none;
  }
}

.closed {
  display: none;
}

@media (max-width: 800px) {
  .Footer {
    justify-content: space-between;
    height: 90px;
  }

  .navbar {
    display: none;
    transition: 1s ease;
  }

  .navbar-active {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    background-color: white;
    inset: 0;
    height: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    position: fixed;
    margin: 0 auto;
    align-items: center;
    margin-top: 1rem;
    height: 100%;
    inset: 0;
    background-color: white;
    z-index: 1;
    padding-inline-start: 0;

    li {
      margin: 2rem;

      a, div {
        font-size: 20px;
        color: black;
        font-weight: bolder;

        @media (max-width: 400px) {
          font-size: 15px !important;
        }
      }
    }
  }
  .changer {
    .menu {
      display: block;
    }
    .menu-inactive {
      display: none;
    }
  }
  .closed {
      display: block;
      cursor: pointer;
  }
}
