@keyframes blink {
  0% {opacity: 1;}
  40% {opacity: 1;}
  60% {opacity: 0;}
  100% {opacity: 0;}
}

.Main {
  min-height: 100vh;
  max-width: 100%;
  background:linear-gradient(45deg,rgba(216, 46, 8, 0.085),rgba(62, 7, 189, 0.126));
  position: relative;

  @media (max-height: 600px) {
    padding-top: 60px;
  }

  @media (max-height: 451px) {
    padding-top: 80px;
  }

  .about-me {
    display: block;
    margin: auto;
    text-align: center;
    flex-wrap: wrap;
    white-space: pre-line;
    font-size: 16px;
    width: 90%;

    @media (max-width: 900px) {
      width: 90%;
    }

    @media (max-width: 768px) {
      padding-top: 40px;
    }

    @media (max-height: 600px) {
      padding-top: 80px;
    }

    @media (max-height: 451px) {
      padding-top: 120px;
    }
  }
}

.main-container {
  display: flex;
  height: 90vh;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-left: 3.445rem;

  .text {
    line-height: 55px;

    .p {
      text-transform: uppercase;
      font-size: 22px;
      display: inline-flex;
    }

    h1 {
      font-size: 50px;
      text-transform: uppercase;
    }

    p {
      text-transform: uppercase;
      font-size: 22px;
    }
  }

  .main-image {
    height: 250px;
    width: 250px;

    @media (min-width: 768px) {
    padding-right: 50px;
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

  .buttons {
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
      justify-content: center;
    }

    button {
      padding: 15px 40px;
      margin-top: 2rem;
      margin-right: 1rem;
      background: linear-gradient(25deg,rgba(1, 60, 210, 0.753),rgb(255, 72, 6));
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: bolder;

      &:hover {
          background: #fff;
          color: firebrick;
        }
    }
  }

  @media (max-width: 768px) {
    .text {
      white-space: nowrap;
      line-height: 30px;
      padding-top: 80px;

      p {
        font-size: 11px;
      }
      h1 {
        font-size: 23px;
      }
    }
    .buttons {
      button {
        padding: 8px 20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .main-container {
    margin-left: 0;
    text-align: center;
    flex-direction: column-reverse;
    justify-content: center;
  }
  .main-image {
    width: 250px;
  }
}
