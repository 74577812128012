@media (min-width: 800px) {
  .works-header {
    text-align: center;
    margin-bottom: 30px;
    font-style: italic;
    font-size: 16px;
    color: #666;
    padding: 50px;
  }

  .works {
    min-height: 100vh;
    max-width: 100%;
    margin-left: 50px;
    padding-top: 50px;
    position: relative;
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 4rem;
    padding-bottom: 50px;

    .cards {
      position: relative;

      img {
        display: block;
        margin: auto;
        max-width: 342px;
        max-height: 220px;
        height: auto;
        width: auto;
        object-fit: scale-down;
      }

      .project-explanation {
        display: block;
        margin: auto;
        width: 342px;
      }

      .button-container {
        display: flex;
        margin: auto;
        width: 75%;
        justify-content: center;
        padding-top: 5px;
      }

      button {
        padding: 7.5px 20px;
        background: linear-gradient(
          25deg,
          rgba(1, 60, 210, 0.753),
          rgb(255, 72, 6)
        );
        color: #fff;
        border: 1px solid #fff;
        cursor: pointer;
        border-radius: 5px;
        text-transform: uppercase;
        font-weight: bolder;

        &:hover {
          background: #fff;
          color: firebrick;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .works {
    display: block;
    min-height: 100vh;
    position: relative;
    padding-bottom: 50px;
  }

  .cards {
    position: relative;
    padding-top: 35px;

    img {
      display: block;
      margin: auto;
      max-width: 342px;
      max-height: 220px;
      height: auto;
      width: 90%;
      object-fit: scale-down;
    }

    .project-explanation {
      display: block;
      margin: auto;
      max-width: 342px;
      width: 90%;
    }

    .button-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 0px 20px 0 20px;
    }

    button {
      padding: 7.5px 20px;
      background: linear-gradient(
        25deg,
        rgba(1, 60, 210, 0.753),
        rgb(255, 72, 6)
      );
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: bolder;
      overflow-wrap: break-word;
    }
  }
}
